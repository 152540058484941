<template>
    <ContentLoader :height="450" secondaryColor="#ccd8ff" primaryColor="#e7ecff">
        <rect x="5" y="5" rx="0" ry="0" width="30" height="30" />
        <rect x="55" y="10" rx="0" ry="0" width="40" height="5" />
        <rect x="55" y="18" rx="0" ry="0" width="40" height="5" />
        <rect x="120" y="5" rx="0" ry="0" width="30" height="30" />
        <rect x="170" y="10" rx="0" ry="0" width="40" height="5" />
        <rect x="170" y="18" rx="0" ry="0" width="40" height="5" />
        <rect x="5" y="45" rx="0" ry="0" width="30" height="30" />
        <rect x="55" y="50" rx="0" ry="0" width="40" height="5" />
        <rect x="55" y="58" rx="0" ry="0" width="40" height="5" />
        <rect x="120" y="45" rx="0" ry="0" width="30" height="30" />
        <rect x="170" y="50" rx="0" ry="0" width="40" height="5" />
        <rect x="170" y="58" rx="0" ry="0" width="40" height="5" />
        <rect x="5" y="85" rx="0" ry="0" width="30" height="30" />
        <rect x="55" y="90" rx="0" ry="0" width="40" height="5" />
        <rect x="55" y="98" rx="0" ry="0" width="40" height="5" />
        <rect x="120" y="85" rx="0" ry="0" width="30" height="30" />
        <rect x="170" y="90" rx="0" ry="0" width="40" height="5" />
        <rect x="170" y="98" rx="0" ry="0" width="40" height="5" />
        <rect x="5" y="125" rx="0" ry="0" width="30" height="30" />
        <rect x="55" y="130" rx="0" ry="0" width="40" height="5" />
        <rect x="55" y="138" rx="0" ry="0" width="40" height="5" />
        <rect x="120" y="125" rx="0" ry="0" width="30" height="30" />
        <rect x="170" y="130" rx="0" ry="0" width="40" height="5" />
        <rect x="170" y="138" rx="0" ry="0" width="40" height="5" />
        <rect x="5" y="165" rx="0" ry="0" width="30" height="30" />
        <rect x="55" y="170" rx="0" ry="0" width="40" height="5" />
        <rect x="55" y="178" rx="0" ry="0" width="40" height="5" />
        <rect x="120" y="165" rx="0" ry="0" width="30" height="30" />
        <rect x="170" y="170" rx="0" ry="0" width="40" height="5" />
        <rect x="170" y="178" rx="0" ry="0" width="40" height="5" />

    </ContentLoader>
</template>
<script>
    import { ContentLoader } from 'vue-content-loader';
    export default {
        components : {
            ContentLoader
        }
    }
</script>
